import { Inview } from './utility';

export default function historyOrder() {
  const historyOrderElement = document.querySelector('.js-history-order');
  const switchElement = historyOrderElement?.querySelector('.historySort') || null;
  const tableElement = historyOrderElement?.querySelector('.history__table') || null;

  if (historyOrderElement === null || switchElement === null || tableElement === null) return;

  new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'data-exclusive-button' &&
        mutation.oldValue !== mutation.target.dataset.exclusiveButton
      ) {
        Array.from(tableElement.children)
          .reverse()
          .forEach((element) => {
            Inview.remove(element, true);
            tableElement.appendChild(element);
            Inview.add(element);
          });
      }
    });
  }).observe(switchElement, {
    attributes: true,
    attributeOldValue: true,
    attributeFilter: ['data-exclusive-button'],
  });
}
