export const Inview = {
  controller: new ScrollMagic.Controller(),
  scenes: new Map(),
  add(element) {
    this.remove(element);

    const dataInviewPosition = element.dataset.inviewPosition;
    const inviewPosition =
      typeof dataInviewPosition === 'undefined' ? 0.9 : Number(dataInviewPosition);
    const scene = new ScrollMagic.Scene({
      triggerElement: element,
      triggerHook: inviewPosition,
      reverse: false,
    })
      .setClassToggle(element, '-inview')
      .addTo(this.controller);

    this.scenes.set(element, scene);
  },
  remove(element, reset = false) {
    if (this.scenes.has(element)) {
      const scene = this.scenes.get(element);

      scene.removeClassToggle(reset);
      scene.remove();
      this.scenes.delete(element);
    }
  },
};
