export default function accordion() {
  const accordionNodeList = document.querySelectorAll('.js-accordion');

  accordionNodeList.forEach((accordionElement) => {
    const buttonElements = accordionElement.querySelectorAll('.js-accordionBtn');
    const contentElement = accordionElement.querySelector('.js-accordionContent');
    let isAnimate = false;

    contentElement.addEventListener('transitionend', (event) => {
      if (event.currentTarget === event.target) {
        event.target.style.removeProperty('display');
        event.target.style.removeProperty('height');
        isAnimate = false;
      }
    });

    const buttonClickHandler = (event) => {
      event.preventDefault();
      if (isAnimate) {
        contentElement.style.height = accordionElement.classList.contains('_open')
          ? 0
          : `${contentElement.scrollHeight}px`;
      } else {
        contentElement.style.display = 'block';
        if (accordionElement.classList.contains('_open')) {
          contentElement.style.height = `${contentElement.scrollHeight}px`;
          // eslint-disable-next-line no-unused-expressions
          contentElement.offsetHeight; // reflow
          contentElement.style.height = 0;
        } else {
          contentElement.style.height = 0;
          // eslint-disable-next-line no-unused-expressions
          contentElement.offsetHeight; // reflow
          contentElement.style.height = `${contentElement.scrollHeight}px`;
        }
        isAnimate = true;
      }
      if (accordionElement.classList.contains('_open')) {
        accordionElement.classList.remove('_open');
      } else {
        accordionElement.classList.add('_open');
      }
    };

    buttonElements.forEach((element) => {
      element.addEventListener('click', buttonClickHandler);
    });
  });
}
