export default function slideBanner() {
  document.querySelectorAll('.js-bannerSlider').forEach((element) => {
    // eslint-disable-next-line no-new
    new Swiper(element, {
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      speed: 1500,
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 50,
      breakpoints: {
        1025: {
          spaceBetween: 30,
        },
      },
      pagination: {
        el: element.querySelector('.swiper-pagination'),
        clickable: true,
      },
    });
  });
}
