export default function slider() {
  const promiseTargets = {
    'js-inview': '-inview',
    'js-imageloaded': '-imageloaded',
  };
  const promiseTargetsKeys = Object.keys(promiseTargets);

  document.querySelectorAll('.js-mvSlider').forEach((element) => {
    const slideNodeList = element.querySelectorAll('.swiper-slide');

    if (slideNodeList.length < 2) return;

    const swiperInstance = new Swiper(element, {
      effect: 'fade',
      speed: 2000,
      loop: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      on: {
        init() {
          if (this.params.autoplay.enabled) {
            this.params.autoplay.delay += this.params.speed;
          }
        },
      },
    });

    if (swiperInstance.params.autoplay.enabled) {
      swiperInstance.once('slideChange', () => {
        swiperInstance.params.autoplay.delay -= swiperInstance.params.speed;
      });

      swiperInstance.autoplay.stop();

      const promiseItems = [];

      promiseTargetsKeys.forEach((className) => {
        promiseItems.push(
          new Promise((resolve) => {
            const targetElement = element.closest(`.${className}`);

            if (targetElement === null) resolve();

            const isDone = () => targetElement.classList.contains(`${promiseTargets[className]}`);

            if (isDone()) {
              resolve();
            } else {
              const observer = new MutationObserver(() => {
                if (isDone()) {
                  observer.disconnect();
                  resolve();
                }
              });

              observer.observe(targetElement, {
                attributes: true,
                attributeFilter: ['class'],
              });
            }
          }),
        );
      });

      Promise.all(promiseItems).then(async () => {
        await new Promise((resolve) => {
          resolve();
        });
        swiperInstance.autoplay.start();
      });
    }
  });
}
