export default function exclusiveButton() {
  const setupButton = (element) => {
    const buttonElements = element.getElementsByTagName('button');

    // eslint-disable-next-line no-param-reassign
    element.dataset.exclusiveButton =
      Array.from(buttonElements).find((buttonElement) =>
        buttonElement.classList.contains('_current'),
      )?.value || '';

    element.addEventListener('click', (event) => {
      const eventElement = event.target.closest('button');

      if (eventElement !== null) {
        Array.from(buttonElements).forEach((buttonElement) => {
          const isCurrent = buttonElement === eventElement;

          buttonElement.classList.toggle('_current', isCurrent);
          if (isCurrent) {
            // eslint-disable-next-line no-param-reassign
            element.dataset.exclusiveButton = eventElement.value;
          }
        });
      }
    });
  };

  document.querySelectorAll('.js-exclusive-button').forEach(setupButton);
}
