import drawer from './drawer';
import accordion from './accordion';
import slideBanner from './slideBanner';
import slideMv from './slideMv';
import imageLoaded from './imageLoaded';
import inview from './inview';
import exclusiveButton from './exclusiveButton';
import historyOrder from './historyOrder';

document.addEventListener('DOMContentLoaded', () => {
  drawer();
  accordion();
  slideBanner();
  slideMv();
  imageLoaded();
  inview();
  exclusiveButton();
  historyOrder();
});
