export default function drawer() {
  const drawer = document.querySelector('.drawer');
  const drawerButton = document.querySelector('.drawerButton');
  const drawerWrap = document.querySelector('.drawerWrap');
  const { body } = document;
  const desktopMatchMedia = window.matchMedia('(min-width: 1025px)');

  if (drawerWrap === null) return;

  const toggleAction = () => {
    body.classList.toggle('_drawerOpen');
    drawerWrap.classList.toggle('_active');
  };

  const removeClass = () => {
    body.classList.remove('_drawerOpen');
    drawerWrap.classList.remove('_active');
  };

  drawerButton.addEventListener('click', () => {
    toggleAction();
  });

  desktopMatchMedia.addListener(removeClass);
}
